import React from 'react';
import Icon from '../Icon';

const Playlist = (props) => (
  <Icon {...props}>
    <svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg" id="playlistIcon">
      <path d="M24 7.3102H0.928577V8.45306H24V7.3102ZM24.0001 0.689819H0.928633V1.81599H24.0001V0.689819ZM0.928528 15.1043H18.5714V13.9776H0.928528V15.1043ZM21.2857 11.1674V19.3102L28.0714 15.2388L21.2857 11.1674Z" fill="#89DBFF" />
    </svg>
  </Icon>
);

export default Playlist;
