export default {
  colors: {
    etGreen: '#05aea5',
    etBlue: '#89DBFF',
    //etBlue: '#0FF4C6',
    etBlack: '#1d1d1d',
    etViolet: '#9480FF',
    etGray: '#324148'
  },
};
